'use client';
import React from 'react';

import NextTopLoader from 'nextjs-toploader';

import { Poppins } from 'next/font/google';

import { supportedLanguages } from 'helper/constant/supported-languages';
import { usePathname } from 'next/navigation';
import Script from 'next/script';
import LayoutProvider from 'provider/layout-provider';

const poppins = Poppins({
  subsets: ['latin'],
  weight: ['400', '600', '700', '800'],
});

const isDev = process.env.NODE_ENV === 'development';
export default function RootLayout({
  children,
  params,
}: {
  children: React.ReactNode;
  params: { lang: string };
}) {
  const pathname = usePathname();
  const completePathName = pathname.split('/').filter((path) => path !== '');
  let isMutliLang = false;
  let lang = 'en';

  supportedLanguages.forEach((element) => {
    isMutliLang = completePathName.includes(element.key);

    if (isMutliLang) {
      lang = element.key;
    }
  });

  return (
    <html lang={lang}>
      <head>
        <link rel="icon" type="image/x-icon" href="/favicon.ico" />
        {!isDev && (
          <>
            <meta
              name="google-adsense-account"
              content="ca-pub-3980206141697810"
            ></meta>
            <link
              rel="preconnect"
              href="https://designtemplate.tech"
              crossOrigin="anonymous"
            />
            <Script
              id="ga tag"
              strategy="beforeInteractive"
              src="https://www.googletagmanager.com/gtag/js?id=G-V50V0TLEJ0"
            ></Script>
            <Script id="ga">
              {`
          
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-V50V0TLEJ0');
          
          `}
            </Script>
            <Script
              async
              strategy="beforeInteractive"
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3980206141697810"
              crossOrigin="anonymous"
            ></Script>
          </>
        )}
      </head>
      <body className={poppins.className}>
        <NextTopLoader showSpinner={false} />
        <LayoutProvider lang={lang}>{children}</LayoutProvider>
      </body>
    </html>
  );
}
