export const supportedLanguages = [
  { value: 'English', key: 'en' },
  { value: 'Marathi', key: 'mr' },
  { value: 'Bengali', key: 'bn' },
  { value: 'Bhojpuri', key: 'bho' },
  { value: 'Hindi', key: 'hi' },
  { value: 'Gujarati', key: 'gu' },
  { value: 'Punjabi', key: 'pa' },
  { value: 'Nepali', key: 'ne' },
  { value: 'Urdu', key: 'ur' },
  { value: 'Sinhala', key: 'si' },
  { value: 'Tamil', key: 'ta' },
  { value: 'Telugu', key: 'te' },
  { value: 'Spanish', key: 'es' },
  { value: 'French', key: 'fr' },
  { value: 'Malayalam', key: 'ml' },
  { value: 'Kannada', key: 'kn' },
];
