import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Footer from 'components/footer/footer';
import Header from 'components/header/header';
import { TopBar } from 'components/header/style';

interface Subcategory {
  title: string;
  url: string;
}

interface Props {
  children: React.ReactNode;
  lang: string;
}
export default function HomeLayout({ children, lang }: Props) {
  return (
    <Box position={'relative'} overflow={'hidden'}>
      <TopBar elevation={2}>
        <Container maxWidth="lg">
          <Header lang={lang} />
        </Container>
      </TopBar>
      {children}
      <Container maxWidth="lg">
        <Footer lang={lang} />
      </Container>
    </Box>
  );
}
