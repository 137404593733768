interface CustomPaletteColor {
	50: string;
	100: string;
	200: string;
	300: string;
	400: string;
	600: string;
	700: string;
	800: string;
	900: string;
	main: string;
	contrastText: string;
}

declare module '@mui/material/styles' {
	// eslint-disable-next-line no-unused-vars
	interface Palette {
		dark: CustomPaletteColor;
		paste: CustomPaletteColor;
		marron: CustomPaletteColor;
	}

	// eslint-disable-next-line no-unused-vars
	interface PaletteOptions {
		dark: CustomPaletteColor;
		paste: CustomPaletteColor;
		marron: CustomPaletteColor;
	}
}

export const grey = {
  900: '#2B3445', // Main Text
  800: '#373F50', // Paragraph
  700: '#4B566B',
  600: '#7D879C',
  500: '#AEB4BE',
  400: '#DAE1E7',
  300: '#E3E9EF',
  200: '#F3F5F9',
  100: '#F6F9FC',
};

export const primary = {
  100: '#',
  200: '#D7D4F2',
  300: '#C9C5ED',
  400: '#AFA8E5',
  500: '#796ED3',
  600: '#6259AD',
  700: '#4B4486',
  800: '#353060',
  900: '#29254D',
  dark: '#29254D',
  main: '#6259AD',
};

export const secondary = {
  100: '#F9C0D7',
  200: '#F9C0D7',
  300: '#F7ABCA',
  400: '#F381AF',
  500: '#EF5795',
  600: '#FF2C84',
  700: '#BC2462',
  800: '#8D1B49',
  900: '#5E1231',
  main: '#FF2C84',
  dark: '#EF5795',
};

export const error = {
  100: '#FFEAEA',
  200: '#FFCBCB',
  300: '#FFA9A9',
  400: '#FF6D6D',
  500: '#FF5353',
  600: '#FF4C4C',
  700: '#FF4242',
  800: '#FF3939',
  900: '#FF2929',
  main: '#E94560',
};

export const success = {
  100: '#E7F9ED',
  200: '#C2F1D1',
  300: '#99E8B3',
  400: '#52D77E',
  500: '#33D067',
  600: '#2ECB5F',
  700: '#27C454',
  800: '#20BE4A',
  900: '#0b7724',
  main: 'rgb(51, 208, 103)',
};

export const blue = {
  100: '#DFE8F9',
  200: '#CFDCF6',
  300: '#BFD1F3',
  400: '#9EB9EE',
  500: '#4E97FD',
  600: '#7EA2E8',
  700: '#5E8BE2',
  800: '#4C71B9',
  900: '#3A5790',
  main: '#4E97FD',
  contrastText: '#FFFFFF',
};

export const marron = {
  50: '#f3f5f9',
  100: '#F6F2ED',
  200: '#F8DBD1',
  300: '#EBBCB3',
  400: '#D89C98',
  600: '#A3545C',
  700: '#883948',
  800: '#6E2438',
  900: '#5B162F',
  main: '#BE7374',
};

export const paste = {
  50: '#F5F5F5',
  100: '#DDFBF1',
  200: '#BDF7E8',
  300: '#97E8DA',
  400: '#76D2CA',
  600: '#36929A',
  700: '#257181',
  800: '#175368',
  900: '#0E3D56',
  main: '#4BB4B4',
  contrastText: '#FFFFFF',
};

export const warning = {
  100: '#FFF8E5',
  main: '#FFCD4E',
  contrastText: '#FFFFFF',
};

export const dark = { main: '#222' };
export const white = { main: '#fff' };
export const theme = {
  dark: { backgroundColor: '#07061B', color: '#ffffff' },
  light: { backgroundColor: '#ffffff', color: '#222222' },
};

export const themeColors = {
  dark,
  grey,
  paste,
  error,
  marron,
  warning,
  primary:{...primary, light: primary[100], dark: primary[900]},
  success,
  secondary,
  info: blue,
  divider: grey[200],
  background: { default: grey[100] },
  text: { primary: grey[900], secondary: grey[800], disabled: grey[400] },
};
