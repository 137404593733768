'use client';
import { Button, InputBase, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import styled from '@mui/material/styles/styled';
import Image from 'next/image';

export const TopBar = styled(AppBar)({
  width: '100%',
  minHeight: '3rem',
  backgroundColor: '#FFFDFC',
  boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10)',
  position: 'sticky',
  '@media (max-width: 368px)': {
    height: '4rem',
  },
  '@media (min-width: 368px) and (max-width: 764px)': {},
  margin: 0,
});

export const StyledLink = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: 'black',
  textDecoration: 'none',
  marginLeft: theme.spacing(2),
  '&:hover': {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  cursor: 'pointer',
}));

export const StyledButton = styled('button')(({ theme }) => ({
  width: '5rem',
  height: '2rem',
  backgroundColor: '#111',
  color: 'white',
  borderRadius: '10px',
  border: 'none',
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const LogoMobile = styled(Image)(({ theme }) => ({
  width: 'auto',
  height: '4rem',
  marginTop: '0.1rem',
  [theme.breakpoints.down(1300)]: {
    width: 'auto',
    height: '2.5rem',
  },
}));

export const SearchBox = styled('form')(({ theme }) => ({
  width: '12rem',
  height: '2rem',
  borderRadius: '5px',
  backgroundColor: '#D9D9D9',
  padding: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '10rem',
  },
}));

export const StyledInput = styled(InputBase)(() => ({
  border: 'none',
  padding: 1,
  background: 'transparent',
  width: '100%',
  height: '100%',
  outline: 'none',
}));

export const StyledSearchButton = styled(Button)(() => ({
  width: '3rem',
  height: '100%',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: '#65Af',
  },
}));
