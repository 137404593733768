
import { components } from './components';
import { themeColors } from './theme-colors';
import { typography } from './typography';

export const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};

const themesOptionList = {
  DEFAULT: {
    typography,
    breakpoints,
    components,
    palette: { ...themeColors },
  },
};

const themeOptions = () => {
  return themesOptionList.DEFAULT;
};

export default themeOptions;
