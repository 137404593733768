export const homepagedata: {
  [key: string]: {
    seo_title: string;
    page_title: string;
    seo_description: string;
    seo_keywords: string;
  };
} = {
  bn: {
    seo_title: 'ভারতে অস্বাভাবিক গন্তব্যগুলি আবিষ্কার করুন',
    page_title:
      'অস্বাভাবিক  আপনার গাইড লুকানো ভ্রমণ রত্ন এবং সারা ভারত জুড়ে অনন্য অভিজ্ঞতা',
    seo_description:
      'অজানা অঞ্চল, লুকানো গ্রাম এবং মনোরম getaways থেকে অনন্য সাংস্কৃতিক অভিজ্ঞতা পর্যন্ত, আমরা আপনাকে ভারতের আঞ্চলিক ভ্রমণ গন্তব্যের সেরা-হালাই গোপন গাইড। ভিড় থেকে দূরে একটি অন্বেষণ যাত্রায় embark।',
    seo_keywords:
      'ভারতে অস্বাভাবিক ভ্রমণ গন্তব্য, লুকানো ভ্রমণ রত্ন,ভারতের অজানা স্থান,ভারতের আঞ্চলিক ভ্রমণ,ভারতের অনন্য অভিজ্ঞতা,অকৃতি পর্যটন গন্তব্য,ভারতীয় সাংস্কৃতিক ভ্রমণ,ভারতের দর্শনীয় getaways',
  },
  bho: {
    page_title:
      'अनपेक्षित प्रवास  संपूर्ण भारतात लपलेल्या प्रवास रत्नांचे आणि अद्वितीय अनुभवांचे मार्गदर्शन करा',
    seo_title: 'भारतातील अपवादात्मक ठिकाणे जाणून घ्या  लपलेले प्रवास रत्न शोधा',
    seo_keywords:
      'भारतातील अपवादात्मक प्रवास स्थळे, छुपी प्रवास रत्न, भारतातील अज्ञात ठिकाणे, भारतातील प्रादेशिक प्रवास, भारतातील अनोखे अनुभव, पर्यावरणीय पर्यटन स्थळे, सांस्कृतिक प्रवास भारत, सुंदर सुट्टी भारतात',
    seo_description:
      'भारतातील अनपेक्षित प्रवास स्थळांचा शोध घ्या. अनपेक्षित भाग, लपलेले गाव, आणि सुंदर सुट्टीतील ठिकाणे ते अद्वितीय सांस्कृतिक अनुभव यांपर्यंत, आम्ही तुम्हाला भारताच्या प्रादेशिक प्रवास स्थळांच्या सर्वोत्तम गुप्त गोष्टींकडे मार्गदर्शन करतो. गर्दीपासून दूर असलेल्या अन्वेषण प्रवासावर जा.',
  },
  gu: {
    seo_title: 'ભારતના અસામાન્ય સ્થળો શોધો  છુપાયેલા મુસાફરીના રત્નો શોધો',
    page_title:
      'અસામાન્ય શોધો  સમગ્ર ભારતમાં છુપાયેલા મુસાફરીના રત્નો અને અનન્ય અનુભવોની તમારી માર્ગદર્શિકા',
    seo_description:
      'અજાણ્યા પ્રદેશો, છુપાયેલા ગામો અને મનોહર રજાઓથી લઈને અનન્ય સાંસ્કૃતિક અનુભવો સુધી, અમે તમને ભારતના પ્રાદેશિક પ્રવાસના શ્રેષ્ઠ રક્ષિત રહસ્યોની માર્ગદર્શન આપીએ છીએ. ભીડથી દૂર શોધખોળની સફરમાં કૂદકો લગાવો.',
    seo_keywords:
      'ભારતમાં અસામાન્ય પ્રવાસન સ્થળો, હિડન ટ્રાવેલ રત્નો, ભારતમાં અજાણ્યા સ્થળો, ભારતમાં પ્રાદેશિક પ્રવાસ, ભારતમાં અનન્ય અનુભવો, ઇકો-ટૂરિઝમ સ્થળો, સાંસ્કૃતિક પ્રવાસ ભારત, ભારતની મનોહર રજાઓ',
  },
  hi: {
    page_title:
      'भारत भर में छुपे हुए यात्रा रत्नों और अनूठे अनुभवों के लिए आपका मार्गदर्शक',
    seo_title:
      'भारत में अभूतपूर्व स्थलों की खोज करें  छुपे हुए यात्रा रत्नों की खोज करें',
    seo_description:
      'भारत के अनपेक्षित यात्रा स्थलों की खोज करें। अनपेक्षित क्षेत्रों, छिपे हुए गांवों और दर्शनीय भागने से लेकर अद्वितीय सांस्कृतिक अनुभवों तक, हम आपको भारत के क्षेत्रीय यात्रा स्थलों के सबसे अच्छी तरह से रखे गए रहस्यों के लिए मार्गदर्शन करते हैं। भीड़ से दूर खोज की यात्रा पर चढ़ें।',
    seo_keywords:
      'भारत में अप्राकृतिक यात्रा गंतव्य, छिपे हुए यात्रा रत्न, भारत में अनजान स्थान, भारत में क्षेत्रीय यात्रा, भारत में अद्वितीय अनुभव, पारिस्थितिक पर्यटन गंतव्य, सांस्कृतिक यात्रा भारत, भारत में दर्शनीय स्थल',
  },
  kn: {
    seo_title:
      'ಭಾರತದಲ್ಲಿ ಅಸಾಮಾನ್ಯ ಸ್ಥಳಗಳನ್ನು ಅನ್ವೇಷಿಸಿ  ಗುಪ್ತ ಪ್ರಯಾಣದ ರತ್ನಗಳನ್ನು ಅನ್ವೇಷಿಸಿ',
    page_title:
      'ಅಸಾಮಾನ್ಯ  ರಹಸ್ಯ ಪ್ರಯಾಣದ ರತ್ನಗಳು ಮತ್ತು ಭಾರತಾದ್ಯಂತ ಅನನ್ಯ ಅನುಭವಗಳಿಗೆ ನಿಮ್ಮ ಮಾರ್ಗದರ್ಶಿ',
    seo_description:
      'ಅನ್ವೇಷಿಸದ ಪ್ರದೇಶಗಳು, ಗುಪ್ತ ಹಳ್ಳಿಗಳು ಮತ್ತು ದೃಶ್ಯಗಳ ಪಾರಾಗಣಗಳಿಂದ ಅನನ್ಯ ಸಾಂಸ್ಕೃತಿಕ ಅನುಭವಗಳವರೆಗೆ, ನಾವು ನಿಮಗೆ ಭಾರತದ ಪ್ರಾದೇಶಿಕ ಪ್ರಯಾಣದ ಸ್ಥಳಗಳ ಉತ್ತಮವಾಗಿ ಸಂರಕ್ಷಿತ ರಹಸ್ಯಗಳನ್ನು ಮಾರ್ಗದರ್ಶನ ಮಾಡುತ್ತೇವೆ. ಜನಸಂದಣಿಯಿಂದ ದೂರವಿರುವ ಅನ್ವೇಷಣೆಯ ಪ್ರಯಾಣದಲ್ಲಿ ತೊಡಗಿಸಿಕೊಳ್ಳಿ.',
    seo_keywords:
      'ಭಾರತದಲ್ಲಿ ಅಸಾಮಾನ್ಯ ಪ್ರಯಾಣದ ತಾಣಗಳು,ಅಡಗಿರುವ ಪ್ರಯಾಣದ ರತ್ನಗಳು,ಭಾರತದಲ್ಲಿ ಅನ್ವೇಷಿಸದ ಸ್ಥಳಗಳು,ಭಾರತದಲ್ಲಿ ಪ್ರಾದೇಶಿಕ ಪ್ರಯಾಣ,ಭಾರತದಲ್ಲಿ ಅನನ್ಯ ಅನುಭವಗಳು,ಪರಿಸರ ಪ್ರವಾಸೋದ್ಯಮ ತಾಣಗಳು,ಸಾಂಸ್ಕೃತಿಕ ಪ್ರಯಾಣ ಭಾರತ,ಭಾರತದಲ್ಲಿ ದೃಶ್ಯ ರವಾನೆ',
  },
  ml: {
    seo_title: 'ഇന്ത്യയിലെ അസാധാരണമായ ലക്ഷ്യസ്ഥാനങ്ങൾ പര്യവേക്ഷണം ചെയ്യുക',
    page_title:
      'അപ്രതീക്ഷിതമായ യാത്രകൾ  ഇന്ത്യയിലുടനീളം മറഞ്ഞിരിക്കുന്ന യാത്രാ രത്നങ്ങളും അതുല്യമായ അനുഭവങ്ങളും',
    seo_description:
      'ഇന്ത്യയിലെ അസാധാരണമായ യാത്രാ ലക്ഷ്യസ്ഥാനങ്ങൾ പര്യവേക്ഷണം ചെയ്യുക. പര്യവേക്ഷണം ചെയ്യാത്ത പ്രദേശങ്ങൾ, മറഞ്ഞിരിക്കുന്ന ഗ്രാമങ്ങൾ, മനോഹരമായ രക്ഷപ്പെടലുകൾ എന്നിവയിൽ നിന്ന് അതുല്യമായ സാംസ്കാരിക അനുഭവങ്ങളിലേക്ക്, ഇന്ത്യയിലെ പ്രാദേശിക യാത്രാ ലക്ഷ്യസ്ഥാനങ്ങളുടെ ഏറ്റവും സൂക്ഷ്മമായി സൂക്ഷിച്ചിരിക്കുന്ന രഹസ്യങ്ങളിലേക്ക് ഞങ്ങൾ നിങ്ങളെ നയിക്കുന്നു. തിരക്കുകളിൽ നിന്ന് അകലെ പര്യവേക്ഷണം നടത്തുക.',
    seo_keywords:
      'ഇന്ത്യയിലെ അസാധാരണ യാത്രാ ലക്ഷ്യസ്ഥാനങ്ങൾ, മറഞ്ഞിരിക്കുന്ന യാത്രാ രത്നങ്ങൾ, ഇന്ത്യയിലെ പര്യവേക്ഷണം ചെയ്യാത്ത സ്ഥലങ്ങൾ, ഇന്ത്യയിലെ പ്രാദേശിക യാത്രകൾ, ഇന്ത്യയിലെ അതുല്യമായ അനുഭവങ്ങൾ, പരിസ്ഥിതി വിനോദ സഞ്ചാര ലക്ഷ്യസ്ഥാനങ്ങൾ, സാംസ്കാരിക യാത്രകൾ ഇന്ത്യ, പ്രകൃതിദൃശ്യങ്ങൾ ഇന്ത്യ',
  },
  mr: {
    seo_title: 'भारतातील अपवादात्मक ठिकाणे जाणून घ्या  लपलेले प्रवास रत्न शोधा',
    page_title:
      'अनपेक्षित प्रवास  संपूर्ण भारतात लपलेल्या प्रवास रत्नांचे आणि अद्वितीय अनुभवांचे मार्गदर्शन करा',
    seo_keywords:
      'भारतातील अपवादात्मक प्रवास स्थळे, छुपी प्रवास रत्न, भारतातील अज्ञात ठिकाणे, भारतातील प्रादेशिक प्रवास, भारतातील अनोखे अनुभव, पर्यावरणीय पर्यटन स्थळे, सांस्कृतिक प्रवास भारत, सुंदर सुट्टी भारतात',
    seo_description:
      'भारतातील अनपेक्षित प्रवास स्थळांचा शोध घ्या. अनपेक्षित भाग, लपलेले गाव, आणि सुंदर सुट्टीतील ठिकाणे ते अद्वितीय सांस्कृतिक अनुभव यांपर्यंत, आम्ही तुम्हाला भारताच्या प्रादेशिक प्रवास स्थळांच्या सर्वोत्तम गुप्त गोष्टींकडे मार्गदर्शन करतो. गर्दीपासून दूर असलेल्या अन्वेषण प्रवासावर जा.',
  },
  ne: {
    seo_title:
      'भारतका असाधारण गन्तव्यहरू अन्वेषण गर्नुहोस्  लुकेका यात्रा रत्नहरू पत्ता लगाउनुहोस्',
    page_title:
      'अनौठो अन्वेषण गर्नुहोस्  भारतभरि लुकेका यात्रा रत्न र अनौंठो अनुभवहरूको तपाईंको मार्गनिर्देशन',
    seo_keywords:
      'भारतमा अप्राकृतिक यात्रा गन्तव्यहरू, लुकेका यात्रा रत्नहरू, भारतमा अन्वेषण नगरेका ठाउँहरू, भारतमा क्षेत्रीय यात्रा, भारतमा अद्वितीय अनुभवहरू, पारिस्थितिक पर्यटन गन्तव्यहरू, सांस्कृतिक यात्रा भारत, दृश्यहरू भारत',
    seo_description:
      'भारतको अनौठो यात्रा गन्तव्यहरू अन्वेषण गर्नुहोस् अनौठोसँग। अन्वेषण क्षेत्रहरू, लुकेका गाउँहरू, र मनोरम getaways देखि अद्वितीय सांस्कृतिक अनुभवहरू सम्म, हामी तपाईंलाई भारतको क्षेत्रीय यात्रा गन्तव्यहरूको सर्वश्रेष्ठ-रक्षित रहस्यहरूको लागि मार्गदर्शन गर्दछौं। भीडबाट टाढा अन्वेषणको यात्रामा embark।',
  },
  pa: {
    page_title:
      'ਵਿਲੱਖਣ ਤਜਰਬੇ ਅਤੇ ਭਾਰਤ ਭਰ ਵਿੱਚ ਲੁਕਵੇਂ ਯਾਤਰਾ ਦੇ ਰਤਨ ਦੀ ਤੁਹਾਡੀ ਗਾਈਡ',
    seo_title: 'ਭਾਰਤ ਦੀਆਂ ਅਸਾਧਾਰਣ ਥਾਵਾਂ ਦੀ ਖੋਜ ਕਰੋ  ਗੁਪਤ ਯਾਤਰਾ ਦੇ ਰਤਨ ਲੱਭੋ',
    seo_keywords:
      'ਭਾਰਤ ਵਿੱਚ ਅਸਾਧਾਰਣ ਯਾਤਰਾ ਦੀਆਂ ਮੰਜ਼ਿਲਾਂ,ਭਾਰਤ ਵਿੱਚ ਲੁਕਵੇਂ ਯਾਤਰਾ ਦੇ ਰਤਨ,ਭਾਰਤ ਵਿੱਚ ਅਣਜਾਣ ਸਥਾਨ,ਭਾਰਤ ਵਿੱਚ ਖੇਤਰੀ ਯਾਤਰਾ,ਭਾਰਤ ਵਿੱਚ ਵਿਲੱਖਣ ਅਨੁਭਵ,ਸੱਭਿਆਚਾਰਕ ਯਾਤਰਾ ਭਾਰਤ,ਭਾਰਤ ਵਿੱਚ ਸ਼ਾਨਦਾਰ ਛੁੱਟੀਆਂ',
    seo_description:
      "ਭਾਰਤ ਦੇ ਅਸਾਧਾਰਣ ਯਾਤਰਾ ਮੰਜ਼ਿਲਾਂ ਦੀ ਖੋਜ ਕਰੋ. ਅਣਜਾਣ ਖੇਤਰਾਂ, ਲੁਕਵੇਂ ਪਿੰਡਾਂ ਅਤੇ ਸ਼ਾਨਦਾਰ ਛੁੱਟੀ ਤੋਂ ਲੈ ਕੇ ਵਿਲੱਖਣ ਸਭਿਆਚਾਰਕ ਤਜ਼ਰਬਿਆਂ ਤੱਕ, ਅਸੀਂ ਤੁਹਾਨੂੰ ਭਾਰਤ ਦੇ ਖੇਤਰੀ ਯਾਤਰਾ ਮੰਜ਼ਿਲਾਂ ਦੇ ਸਭ ਤੋਂ ਵਧੀਆ ਰਾਜ਼ੇ ਰੱਖੇ ਗਏ ਰਾਜ਼ਿਆਂ ਦੀ ਅਗਵਾਈ ਕਰਦੇ ਹਾਂ. ਭੀੜ ਤੋਂ ਦੂਰ ਖੋਜ ਦੀ ਯਾਤਰਾ 'ਤੇ ਜਾਓ.",
  },
  sd: {
    seo_title: 'هندستان ۾ غير معمولي منزلون ڳوليو  لڪيل سفر جا جواهر ڳوليو',
    page_title:
      'غير معمولي ڳوليو  توهان جو هدايتڪار هندستان جي مختلف هنڌن تي لڪيل سفر جا راڻا ۽ منفرد تجربا',
    seo_keywords:
      'هندستان ۾ غير معمولي سفر جون منزلون، ڳجھي سفر جون جواهر، هندستان ۾ اڻڄاتل جڳھون، هندستان ۾ علائقائي سفر، هندستان ۾ منفرد تجربا، ماحولياتي سياحت جون منزلون، ثقافتي سفر هندستان، منظر نامي فرار هندستان',
    seo_description:
      'explore offbeat سان هندستان جي غير معمولي سفر جي منزلن کي دريافت ڪريو. اڻ exploredاتل علائقن کان ، لڪيل ڳوٺن کان ، ۽ خوبصورت فرار کان منفرد ثقافتي تجربن تائين ، اسان توهان کي هندستان جي علائقائي سفر جي منزلن جي بهترين رازن جي رهنمائي ڪندا آهيون. ماڻهن کان پري هڪ جستجو جي سفر تي سوار ٿيو.',
  },
  ta: {
    seo_title: 'இந்தியாவில் உள்ள அசாதாரண இடங்களை ஆராயுங்கள்',
    page_title:
      'அசாதாரணமானவைகளை ஆராயுங்கள்  இந்தியா முழுவதும் மறைக்கப்பட்ட பயண ரத்தினங்கள் மற்றும் தனித்துவமான அனுபவங்களுக்கு உங்கள் வழிகாட்டி',
    seo_keywords:
      'இந்தியாவில் அசாதாரண பயண இடங்கள், மறைக்கப்பட்ட பயண ரத்தினங்கள், இந்தியாவில் ஆராயப்படாத இடங்கள், இந்தியாவில் பிராந்திய பயணம், இந்தியாவில் தனித்துவமான அனுபவங்கள், சுற்றுலா இலக்குகள், கலாச்சார பயணம் இந்தியா, இயற்கை ஓய்வு இந்தியா',
    seo_description:
      'ஆஃப்பீட் மூலம் இந்தியாவின் அசாதாரண பயண இடங்களை ஆராயுங்கள். ஆராயப்படாத பகுதிகள், மறைக்கப்பட்ட கிராமங்கள் மற்றும் அழகிய தப்பிக்கும் இடங்கள் முதல் தனித்துவமான கலாச்சார அனுபவங்கள் வரை, இந்தியாவின் பிராந்திய பயண இடங்களின் சிறந்த ரகசியங்களை நாங்கள் உங்களுக்கு வழிகாட்டுகிறோம். கூட்டத்திலிருந்து விலகி ஒரு ஆய்வு பயணத்தில் இறங்கவும்.',
  },
  te: {
    seo_title: 'భారతదేశంలో అరుదైన గమ్యస్థానాలను అన్వేషించండి',
    page_title:
      'అసాధారణమైన  భారతదేశం అంతటా దాచిన ప్రయాణ రత్నాలు మరియు ప్రత్యేకమైన అనుభవాల కోసం మీ గైడ్ను అన్వేషించండి',
    seo_description:
      'అన్వేషించని ప్రాంతాలు, దాచిన గ్రామాలు, సుందరమైన తప్పించుకునే ప్రదేశాలు నుండి ప్రత్యేకమైన సాంస్కృతిక అనుభవాలకు, భారతదేశం యొక్క ప్రాంతీయ ప్రయాణ గమ్యస్థానాల యొక్క ఉత్తమంగా ఉంచబడిన రహస్యాలకు మేము మిమ్మల్ని నడిపిస్తాము. గుంపు నుండి అన్వేషణ ప్రయాణంలో ప్రవేశించండి.',
    seo_keywords:
      'భారతదేశంలో అసాధారణమైన ప్రయాణ గమ్యస్థానాలు, దాచిన ప్రయాణ రత్నాలు,భారతదేశంలో అన్వేషించని ప్రదేశాలు,భారతదేశంలో ప్రాంతీయ ప్రయాణాలు,భారతదేశంలో ప్రత్యేకమైన అనుభవాలు, పర్యావరణ పర్యాటక గమ్యస్థానాలు,సాంస్కృతిక ప్రయాణాలు భారతదేశం,భారతదేశంలో దృశ్యమాన విహారయాత్రలు',
  },
  ur: {
    page_title:
      'غیر معمولی دریافت کریں  ہندوستان بھر میں پوشیدہ سفری جواہرات اور منفرد تجربات کے لئے آپ کا گائیڈ',
    seo_title:
      'بھارت میں غیر معمولی مقامات دریافت کریں  پوشیدہ سفری قیمتی جواہرات دریافت کریں',
    seo_description:
      'بھارت کی غیر معمولی سفری منزلوں کو دریافت کریں. غیر معمولی علاقوں سے ، پوشیدہ دیہاتوں اور قدرتی فرار سے لے کر منفرد ثقافتی تجربات تک ، ہم آپ کو ہندوستان کی علاقائی سفری منزلوں کے بہترین رازوں کی رہنمائی کرتے ہیں۔ ہجوم سے دور ایک دریافت کے سفر پر سوار ہوں۔',
    seo_keywords:
      'بھارت میں غیر معمولی سفری مقامات، پوشیدہ سفری جواہرات، بھارت میں ناپسندیدہ مقامات، بھارت میں علاقائی سفر، بھارت میں منفرد تجربات، ماحولیاتی سیاحت کی منزلیں، ثقافتی سفر بھارت، قدرتی getaways بھارت',
  },
  es: {
    page_title:
      'Explora lo que no es habitual  Su guía de joyas ocultas de viaje y experiencias únicas en toda la India',
    seo_title:
      'Explora destinos inusuales en la India  Descubre las joyas ocultas de viaje',
    seo_description:
      'Descubra los destinos de viaje de la India fuera de lo normal con Explore Offbeat. Desde regiones inexploradas, pueblos ocultos y escapadas escénicas a experiencias culturales únicas, te guiamos a los secretos mejor guardados de los destinos de viaje regionales de la India. Embárcate en un viaje de exploración lejos de la multitud.',
    seo_keywords:
      'Destinos de viaje fuera de lo normal en la India,Gemas ocultas de viaje,Lugares inexplorados en la India,Viajes regionales en la India,Experiencias únicas en la India,Destinados de ecoturismo,Viajes culturales en la India,Viajes escénicos en la India',
  },
  fr: {
    seo_title:
      'Explorez des destinations hors du commun en Inde  Découvrez des joyaux cachés',
    page_title:
      "Explorez l'extraordinaire  Votre guide des joyaux cachés du voyage et des expériences uniques à travers l'Inde",
    seo_description:
      "Découvrez les destinations de voyage hors du commun de l'Inde avec Explore Offbeat. De régions inexplorées, villages cachés et escapades pittoresques à des expériences culturelles uniques, nous vous guidons vers les secrets les mieux gardés des destinations de voyage régionales de l'Inde.",
    seo_keywords:
      'Destinations de voyage hors du commun en Inde, Joyaux cachés de voyage, Lieux inexplorés en Inde, Voyage régional en Inde, Experiences uniques en Inde, Destinations de tourisme écologique, Voyage culturel en Inde, Fêtes pittoresques en Inde',
  },
  pt: {
    seo_title:
      'Explore destinos estranhos na Índia  Descubra jóias escondidas de viagem',
    page_title:
      'Explore o que é extraordinário  Seu guia para as joias escondidas de viagem e experiências únicas em toda a Índia',
    seo_description:
      'Descubra os destinos de viagem estranhos da Índia com Explore Offbeat. De regiões inexploradas, aldeias ocultas e escapadas pitorescas a experiências culturais únicas, nós o guiamos aos segredos melhor guardados dos destinos de viagem regionais da Índia. Embarque em uma jornada de exploração longe das multidões.',
    seo_keywords:
      'Destinos de viagem fora do comum na Índia,Jascas de viagem ocultas,Lugares inexplorados na Índia, Viagens regionais na Índia,Experiências únicas na Índia,Destinativos de ecoturismo,Viagens culturais na Índia,Viagens panorâmicas na Índia',
  },
  en: {
    seo_title:
      'Explore Offbeat Destinations in India – Uncover Hidden Travel Gems',
    seo_description:
      "Discover India's offbeat travel destinations with Explore Offbeat. From unexplored regions, hidden villages, and scenic getaways to unique cultural experiences, we guide you to the best-kept secrets of India’s regional travel destinations. Embark on a journey of exploration away from the crowds.",
    page_title:
      'Explore Offbeat – Your Guide to Hidden Travel Gems and Unique Experiences Across India',
    seo_keywords:
      'Offbeat travel destinations in India,Hidden travel gems,Unexplored places in India,Regional travel in India,Unique experiences in India,Eco-tourism destinations,Cultural travel India,Scenic getaways India',
  },
};

export const latestpostTranslate: { [key: string]: string } = {
  en: 'Latest Post',
  mr: 'ताजे पोस्ट',
  bn: 'সর্বশেষ পোস্ট',
  bho: 'नवीनतम पोस्ट',
  hi: 'हाल की पोस्ट',
  gu: 'તાજેતરની પોસ્ટ',
  pa: 'ਤਾਜਾ ਪੋਸਟ',
  ne: 'ताजा पोस्ट',
  ur: 'تازہ پوسٹ',
  si: 'අලුත්ම පෝස්ට්',
  ta: 'சமீபத்திய பதிவு',
  te: 'తాజా పోస్ట్',
  es: 'Última publicación',
  fr: 'Dernier article',
  ml: 'അടുത്ത പോസ്റ്റ്',
  kn: 'ಇತ್ತೀಚಿನ ಪೋಸ್ಟ್',
};

export const otherPostTranslate: { [key: string]: string } = {
  en: 'Other Post',
  mr: 'इतर पोस्ट',
  bn: 'অন্যান্য পোস্ট',
  bho: 'अन्य पोस्ट',
  hi: 'अन्य पोस्ट',
  gu: 'અન્ય પોસ્ટ',
  pa: 'ਹੋਰ ਪੋਸਟ',
  ne: 'अन्य पोस्ट',
  ur: 'دیگر پوسٹ',
  si: 'अन्य පෝස්ට්',
  ta: 'மற்ற பதிவு',
  te: 'ఇతర పోస్ట్',
  es: 'Otra publicación',
  fr: 'Autre publication',
  ml: 'മറ്റു പോസ്റ്റ്',
  kn: 'ಇತರ ಪೋಸ್ಟ್',
};

export const headerTranslation: {
  [key: string]: {
    home: string;
    maharashtra: string;
  };
} = {
  en: {
    home: 'Home',
    maharashtra: 'Maharashtra',
  },
  mr: {
    home: 'मुख्यपृष्ठ',
    maharashtra: 'महाराष्ट्र',
  },
  bn: {
    home: 'হোম',
    maharashtra: 'মহারাষ্ট্র',
  },
  bho: {
    home: 'मुखपृष्ठ',
    maharashtra: 'महाराष्ट्र',
  },
  hi: {
    home: 'मुखपृष्ठ',
    maharashtra: 'महाराष्ट्र',
  },
  gu: {
    home: 'હોમ',
    maharashtra: 'મહારાષ્ટ્ર',
  },
  pa: {
    home: 'ਘਰ',
    maharashtra: 'ਮਹਾਰਾਸ਼ਟਰ',
  },
  ne: {
    home: 'घर',
    maharashtra: 'महाराष्ट्र',
  },
  ur: {
    home: 'گھر',
    maharashtra: 'مہاراشٹر',
  },
  si: {
    home: 'මුල් පිටුව',
    maharashtra: 'මහරාෂ්ට්‍ර',
  },
  ta: {
    home: 'முகப்பு',
    maharashtra: 'மஹாராஷ்டிர',
  },
  te: {
    home: 'హోమ్',
    maharashtra: 'మహారాష్ట్ర',
  },
  es: {
    home: 'Inicio',
    maharashtra: 'Maharashtra',
  },
  fr: {
    home: 'Accueil',
    maharashtra: 'Maharashtra',
  },
  ml: {
    home: 'വീട്',
    maharashtra: 'മহারാഷ്ട്ര',
  },
  kn: {
    home: 'ಮೂಲಪುಟ',
    maharashtra: 'ಮಹಾರಾಷ್ಟ್ರ',
  },
};

export const footerTranslations: {
  [key: string]: {
    quickLink: string;
    home: string;
    about: string;
    privacyPolicy: string;
    categories: string;
    maharashtra: string;
  };
} = {
  en: {
    quickLink: 'Quick link',
    home: 'Home',
    about: 'About',
    privacyPolicy: 'Privacy Policy',
    categories: 'Categories',
    maharashtra: 'Maharashtra',
  },
  mr: {
    quickLink: 'त्वरित लिंक',
    home: 'मुख्यपृष्ठ',
    about: 'आमच्याबद्दल',
    privacyPolicy: 'गोपनीयता धोरण',
    categories: 'वर्ग',
    maharashtra: 'महाराष्ट्र',
  },
  bn: {
    quickLink: 'দ্রুত লিঙ্ক',
    home: 'হোম',
    about: 'সম্পর্কিত',
    privacyPolicy: 'গোপনীয়তা নীতি',
    categories: 'বিভাগ',
    maharashtra: 'মহারাষ্ট্র',
  },
  bho: {
    quickLink: 'तत्काल लिंक',
    home: 'मुखपृष्ठ',
    about: 'हमरा बारे में',
    privacyPolicy: 'गोपनीयता नीति',
    categories: 'श्रेणियाँ',
    maharashtra: 'महाराष्ट्र',
  },
  hi: {
    quickLink: 'त्वरित लिंक',
    home: 'होम',
    about: 'हमारे बारे में',
    privacyPolicy: 'गोपनीयता नीति',
    categories: 'श्रेणियाँ',
    maharashtra: 'महाराष्ट्र',
  },
  gu: {
    quickLink: 'તાત્કાલિક લિંક',
    home: 'મુખ્ય પૃષ્ઠ',
    about: 'અમારા વિશે',
    privacyPolicy: 'ગોપનીયતા નીતિ',
    categories: 'વર્ગો',
    maharashtra: 'મહારાષ્ટ્ર',
  },
  pa: {
    quickLink: 'ਤਤਕਾਲ ਲਿੰਕ',
    home: 'ਮੁੱਖ ਪੰਨਾ',
    about: 'ਸਾਡੇ ਬਾਰੇ',
    privacyPolicy: 'ਗੋਪਨੀਯਤਾ ਨੀਤੀ',
    categories: 'ਸ਼੍ਰੇਣੀਆਂ',
    maharashtra: 'ਮਹਾਰਾਸ਼ਟਰ',
  },
  ne: {
    quickLink: 'छिटो लिङ्क',
    home: 'मुखपृष्ठ',
    about: 'हाम्रो बारेमा',
    privacyPolicy: 'गोपनीयता नीति',
    categories: 'वर्ग',
    maharashtra: 'महाराष्ट्र',
  },
  ur: {
    quickLink: 'فوری لنک',
    home: 'گھر',
    about: 'ہمارے بارے میں',
    privacyPolicy: 'رازداری کی پالیسی',
    categories: 'زمرے',
    maharashtra: 'مہاراشٹر',
  },
  si: {
    quickLink: 'ඉක්මන් සබැඳි',
    home: 'මුල් පිටුව',
    about: 'අප ගැන',
    privacyPolicy: 'පෞද්ගලිකත්ව ප්‍රතිපත්ති',
    categories: 'ප්‍රවර්ග',
    maharashtra: 'මහාරාෂ්ට්‍ර',
  },
  ta: {
    quickLink: 'விரைவு இணைப்பு',
    home: 'முகப்பு',
    about: 'எங்கள் பற்றி',
    privacyPolicy: 'தடவுச்சொல் கொள்கை',
    categories: 'வகைகள்',
    maharashtra: 'மகாராஷ்டிரா',
  },
  te: {
    quickLink: 'తక్షణ లింక్',
    home: 'హోమ్',
    about: 'మా గురించి',
    privacyPolicy: 'గోప్యతా విధానం',
    categories: 'వర్గాలు',
    maharashtra: 'మహారాష్ట్ర',
  },
  es: {
    quickLink: 'Enlace rápido',
    home: 'Inicio',
    about: 'Sobre nosotros',
    privacyPolicy: 'Política de privacidad',
    categories: 'Categorías',
    maharashtra: 'Maharashtra',
  },
  fr: {
    quickLink: 'Lien rapide',
    home: 'Accueil',
    about: 'À propos',
    privacyPolicy: 'Politique de confidentialité',
    categories: 'Catégories',
    maharashtra: 'Maharashtra',
  },
  ml: {
    quickLink: 'ഫാസ്റ്റ് ലിങ്ക്',
    home: 'ഹോം',
    about: 'ഞങ്ങളേക്കുറിച്ച്',
    privacyPolicy: 'ഗോപ്പന 정책',
    categories: 'വിഭാഗങ്ങൾ',
    maharashtra: 'മഹാരാഷ്ട്ര',
  },
  kn: {
    quickLink: 'ತ್ವರಿತ ಲಿಂಕ್',
    home: 'ಮೂಲೆ',
    about: 'ನಮ್ಮ ಬಗ್ಗೆ',
    privacyPolicy: 'ಗೋಪನೀಯತಾ ನೀತಿ',
    categories: 'ವರ್ಗಗಳು',
    maharashtra: 'ಮಹಾರಾಷ್ಟ್ರ',
  },
};

//listing page data
export const listingStatic: {
  [key: string]: {
    category: string;
    topics: string;
    stayUpdated: string;
  };
} = {
  en: {
    category:
      'Discover in-depth travel guides and expert insights in the - category category. Stay updated with the latest travel trends and tips from Exploreoffbeat.',
    topics:
      'Explore trending travel topics and valuable tips from industry experts at Exploreoffbeat. Keep informed about the latest updates on - topic.',
    stayUpdated: 'Stay updated with the latest travel news in - category.',
  },
  mr: {
    category:
      '- category क्षेत्रातील तपशीलवार यात्रा मार्गदर्शक आणि तज्ज्ञांचे अभिप्राय पहा. Exploreoffbeat कडून नवीनतम प्रवास ट्रेंड्स आणि टिप्ससह अद्यतित रहा.',
    topics:
      '- topic वरून प्रवासाशी संबंधित ट्रेंडिंग विषय आणि महत्त्वाच्या टिप्स मिळवा. नवीनतम अद्यतने मिळवण्यासाठी माहिती मिळवा.',
    stayUpdated: '- category मध्ये प्रवासातील नवीनतम माहितीबद्दल अद्यतित रहा.',
  },
  bn: {
    category:
      '- category শ্রেণীতে বিস্তারিত ভ্রমণ গাইড এবং বিশেষজ্ঞদের মতামত খুঁজুন। Exploreoffbeat থেকে সর্বশেষ ভ্রমণ প্রবণতা এবং টিপস নিয়ে আপডেট থাকুন।',
    topics:
      '- topic এর উপর ভ্রমণ সম্পর্কিত ট্রেন্ডিং বিষয় এবং মূল্যবান টিপস আবিষ্কার করুন। সর্বশেষ আপডেটের সাথে যোগাযোগে থাকুন।',
    stayUpdated: '- category তে সর্বশেষ ভ্রমণ সংবাদ নিয়ে আপডেট থাকুন।',
  },
  bho: {
    category:
      '- category श्रेणी में विस्तृत यात्रा मार्गदर्शिका और विशेषज्ञों की राय खोजें। Exploreoffbeat से नवीनतम यात्रा प्रवृत्तियों और सुझावों के साथ अद्यतित रहें।',
    topics:
      '- topic पर यात्रा संबंधित ट्रेंडिंग विषय और महत्वपूर्ण टिप्स खोजें। नवीनतम अपडेट के बारे में जानकारी रखें।',
    stayUpdated: '- category में नवीनतम यात्रा समाचार के साथ अद्यतित रहें।',
  },
  hi: {
    category:
      '- category श्रेणी में विस्तृत यात्रा गाइड और विशेषज्ञों की राय जानें। Exploreoffbeat से नवीनतम यात्रा रुझानों और सुझावों के साथ अद्यतित रहें।',
    topics:
      '- topic पर यात्रा से संबंधित ट्रेंडिंग विषय और उपयोगी टिप्स खोजें। नवीनतम अपडेट की जानकारी रखें।',
    stayUpdated: '- category में नवीनतम यात्रा समाचार के साथ अद्यतित रहें।',
  },
  gu: {
    category:
      '- category શ્રેણીમાં વિસ્તૃત યાત્રા માર્ગદર્શિકા અને નિષ્ણાતોની ટિપ્પણીઓ શોધો. Exploreoffbeat પાસેથી નવીનતમ યાત્રા ટ્રેન્ડ્સ અને સૂચનો સાથે અપડેટ રહો.',
    topics:
      '- topic વિષય માટે યાત્રા સંબંધિત ટ્રેન્ડિંગ વિષય અને મહત્વપૂર્ણ ટિપ્પણીઓ મેળવો. નવીનતમ સુધારાઓ વિશે જાણો.',
    stayUpdated: '- category માં નવીનતમ યાત્રા સમાચાર સાથે અપડેટ રહો.',
  },
  pa: {
    category:
      '- category ਸ਼੍ਰੇਣੀ ਵਿੱਚ ਵਿਸ਼ਤ੍ਰਿਤ ਯਾਤਰਾ ਮਾਰਗਦਰਸ਼ਿਕਾ ਅਤੇ ਵਿਸ਼ੇਸ਼ਜ੍ਹੀਆਂ ਦੀਆਂ ਰਾਏ ਖੋਜੋ। Exploreoffbeat ਤੋਂ ਨਵੀਆਂ ਯਾਤਰਾ ਰੁਝਾਨਾਂ ਅਤੇ ਸੁਝਾਵਾਂ ਨਾਲ ਅਪਡੇਟ ਰਹੋ।',
    topics:
      '- topic ਬਾਰੇ ਯਾਤਰਾ ਨਾਲ ਸੰਬੰਧਿਤ ਟ੍ਰੇਂਡਿੰਗ ਵਿਸ਼ੇ ਅਤੇ ਕੀਮਤੀ ਟਿੱਪਣੀਆਂ ਖੋਜੋ। ਤਾਜ਼ਾ ਅਪਡੇਟਾਂ ਨਾਲ ਅਪਡੇਟ ਰਹੋ।',
    stayUpdated: '- category ਵਿੱਚ ਤਾਜ਼ਾ ਯਾਤਰਾ ਖ਼ਬਰਾਂ ਨਾਲ ਅਪਡੇਟ ਰਹੋ।',
  },
  ne: {
    category:
      '- category श्रेणीमा विस्तृत यात्रा मार्गदर्शिका र विशेषज्ञहरूको राय पत्ता लगाउनुहोस्। Exploreoffbeat बाट नवीनतम यात्रा प्रवृत्तिहरू र सुझावहरूसँग अद्यतित रहनुहोस्。',
    topics:
      '- topic मा यात्रा सम्बन्धी ट्रेन्डिङ विषयहरू र महत्वपूर्ण सुझावहरू अन्वेषण गर्नुहोस्। नवीनतम अपडेटहरूको बारेमा जानकारी राख्नुहोस्।',
    stayUpdated: '- category मा नवीनतम यात्रा समाचारसँग अद्यतित रहनुहोस्।',
  },
  ur: {
    category:
      '- category زمرے میں تفصیلی سفر گائیڈ اور ماہرین کی رائے دریافت کریں۔ Exploreoffbeat سے تازہ ترین سفر کے رجحانات اور تجاویز کے ساتھ اپ ڈیٹ رہیں۔',
    topics:
      '- topic پر سفر سے متعلقہ ٹرینڈنگ موضوعات اور قیمتی ٹپس تلاش کریں۔ تازہ ترین اپ ڈیٹس سے باخبر رہیں۔',
    stayUpdated: '- category میں تازہ ترین سفر کی خبروں کے ساتھ اپ ڈیٹ رہیں۔',
  },
  si: {
    category:
      '- category කාණ්ඩය තුළ සවිස්තරාත්මක ගමන මාර්ගෝපදේශ සහ විශේෂඥයන්ගේ අදහස් සොයා ගන්න. Exploreoffbeat වෙතින් නවතම ගමනේ ප්‍රවණතා සහ උපදෙස් සමඟ යාවත්කාලීන වන්න.',
    topics:
      '- topic පිළිබඳව ගමනේ විශේෂඥයන්ගෙන් ප්‍රවණ විෂය සහ වටිනා උපදෙස් සොයා ගන්න. නවතම යාවත්කාලීන කිරීම් ගැන දැනුවත් වන්න.',
    stayUpdated: '- category හි නවතම ගමනේ තොරතුරු සමඟ යාවත්කාලීන වන්න.',
  },
  ta: {
    category:
      '- category வகையில் விரிவான பயண வழிகாட்டிகள் மற்றும் நிபுணர்களின் கருத்துக்களை தேடுங்கள். Exploreoffbeat மூலம் புதிய பயண பாணிகள் மற்றும் குறிப்பு மூலம் புதுப்பிக்கப்பட்ட நிலை.',
    topics:
      '- topic பற்றி பயண நிபுணர்களிடமிருந்து பிரபலமான தலைப்புகள் மற்றும் மதிப்பீட்டுக் கருத்துக்களைப் பறிமாற்றுங்கள். புதிய விவரங்களின் மீது தகவல் அறியுங்கள்.',
    stayUpdated:
      '- category இல் புதிய பயண செய்திகள் மற்றும் தகவல்களுடன் புதுப்பிக்கவும்.',
  },
  te: {
    category:
      '- category విభాగంలో విస్తృత యాత్రా మార్గదర్శకాలు మరియు నిపుణుల అభిప్రాయాలు తెలుసుకోండి. Exploreoffbeat నుండి తాజా యాత్రా ధోరణులు మరియు చిట్కాలతో అప్డేట్‌గా ఉండండి.',
    topics:
      '- topic పై యాత్ర సంబంధిత ట్రెండింగ్ అంశాలు మరియు విలువైన చిట్కాలు పొందండి. తాజా నవీకరణలపై అవగాహన ఉంచండి.',
    stayUpdated:
      '- category లో తాజా యాత్రా సమాచారం మరియు వార్తలతో అప్డేట్‌గా ఉండండి.',
  },
};

export const metadatatranslations: {
  [key: string]: {
    title: (data: any) => any;
    description: (data: any) => any;
  };
} = {
  en: {
    title: (data: any) =>
      `Explore all topics like ${data?.topics?.[0]?.name}, ${data?.topics?.[1]?.name}`,
    description: (data: any) =>
      `Explore a wide range of travel topics from ${data?.topics?.[0]?.name} to ${data?.topics?.[1]?.name} on Exploreoffbeats. Get insights, tips, and more with our expert travel articles.`,
  },
  mr: {
    title: (data: any) =>
      `सर्व विषय शोधा जसे ${data?.topics?.[0]?.name}, ${data?.topics?.[1]?.name}`,
    description: (data: any) =>
      `Exploreoffbeats वर ${data?.topics?.[0]?.name} पासून ${data?.topics?.[1]?.name} पर्यंत प्रवासाशी संबंधित विविध विषयांचा शोध घ्या. आमच्या तज्ञांच्या लेखांसह प्रवास टिप्स आणि ज्ञान मिळवा.`,
  },
  bn: {
    title: (data: any) =>
      `${data?.topics?.[0]?.name}, ${data?.topics?.[1]?.name} এর মতো সমস্ত ভ্রমণ বিষয় অন্বেষণ করুন`,
    description: (data: any) =>
      `Exploreoffbeats এ ${data?.topics?.[0]?.name} থেকে ${data?.topics?.[1]?.name} পর্যন্ত ভ্রমণ বিষয়ের একটি বিস্তৃত পরিসর অন্বেষণ করুন। আমাদের বিশেষজ্ঞ প্রবন্ধের মাধ্যমে অন্তর্দৃষ্টি, টিপস এবং আরও অনেক কিছু পান।`,
  },
  bho: {
    title: (data: any) =>
      `${data?.topics?.[0]?.name}, ${data?.topics?.[1]?.name} जइसन सब विषयन के खोज कइल जाव`,
    description: (data: any) =>
      `Exploreoffbeats पर ${data?.topics?.[0]?.name} से लेके ${data?.topics?.[1]?.name} ले यात्रा विषयन के खोज कइल जाव। हमरा विशेषज्ञ लेखन से यात्रा टिप्स और जानकारी पावल जाव।`,
  },
  hi: {
    title: (data: any) =>
      `${data?.topics?.[0]?.name}, ${data?.topics?.[1]?.name} जैसे सभी यात्रा विषयों का अन्वेषण करें`,
    description: (data: any) =>
      `Exploreoffbeats पर ${data?.topics?.[0]?.name} से ${data?.topics?.[1]?.name} तक यात्रा से संबंधित विषयों की एक विस्तृत श्रृंखला का अन्वेषण करें। हमारे विशेषज्ञ लेखों के साथ यात्रा टिप्स और जानकारी प्राप्त करें।`,
  },
  gu: {
    title: (data: any) =>
      `${data?.topics?.[0]?.name}, ${data?.topics?.[1]?.name} જેવા બધા પ્રવાસ વિષયોનો અનુસંધાન કરો`,
    description: (data: any) =>
      `Exploreoffbeats પર ${data?.topics?.[0]?.name} થી ${data?.topics?.[1]?.name} સુધીના પ્રવાસ વિષયો શોધો. અમારા નિષ્ણાંત લેખો સાથે પ્રવાસ ટિપ્સ અને માહિતી મેળવો.`,
  },
  pa: {
    title: (data: any) =>
      `${data?.topics?.[0]?.name}, ${data?.topics?.[1]?.name} ਵਰਗੇ ਸਾਰੇ ਸਫ਼ਰ ਦੇ ਵਿਸ਼ਿਆਂ ਦਾ ਪਤਾ ਲਗਾਓ`,
    description: (data: any) =>
      `Exploreoffbeats 'ਤੇ ${data?.topics?.[0]?.name} ਤੋਂ ਲੈ ਕੇ ${data?.topics?.[1]?.name} ਤੱਕ ਸਫ਼ਰ ਦੇ ਵਿਸ਼ਿਆਂ ਦੀ ਇੱਕ ਵਿਸ਼ਾਲ ਸ਼੍ਰੇਣੀ ਦੀ ਖੋਜ ਕਰੋ। ਸਾਡੇ ਮਾਹਿਰ ਲੇਖਾਂ ਦੇ ਨਾਲ ਯਾਤਰਾ ਟਿੱਪਸ ਅਤੇ ਜਾਣਕਾਰੀ ਪ੍ਰਾਪਤ ਕਰੋ।`,
  },
  ne: {
    title: (data: any) =>
      `${data?.topics?.[0]?.name}, ${data?.topics?.[1]?.name} जस्ता सबै यात्रा विषयहरूको अन्वेषण गर्नुहोस्`,
    description: (data: any) =>
      `Exploreoffbeats मा ${data?.topics?.[0]?.name} देखि ${data?.topics?.[1]?.name} सम्मका यात्रा सम्बन्धी विषयहरूको विस्तृत दायरा अन्वेषण गर्नुहोस्। हाम्रो विशेषज्ञ लेखहरूको साथ यात्रा सुझावहरू र जानकारी प्राप्त गर्नुहोस्।`,
  },
  ur: {
    title: (data: any) =>
      `${data?.topics?.[0]?.name}, ${data?.topics?.[1]?.name} جیسے تمام سفری موضوعات کو دریافت کریں`,
    description: (data: any) =>
      `Exploreoffbeats پر ${data?.topics?.[0]?.name} سے ${data?.topics?.[1]?.name} تک سفری موضوعات کی ایک وسیع رینج کو دریافت کریں۔ ہمارے ماہر مضامین کے ساتھ سفری تجاویز اور معلومات حاصل کریں۔`,
  },
  si: {
    title: (data: any) =>
      `${data?.topics?.[0]?.name}, ${data?.topics?.[1]?.name} වැනි සියලුම ගමන් විෂයන් සොයා බලන්න`,
    description: (data: any) =>
      `Exploreoffbeats හි ${data?.topics?.[0]?.name} සිට ${data?.topics?.[1]?.name} දක්වා ගමන් විෂයන්හි පුළුල් පරාසයක් සොයා බලන්න. අපගේ විශේෂඥ ලිපි සමඟ ගමන් සම්බන්ධ උපදෙස් සහ දැනුම ලබා ගන්න.`,
  },
  ta: {
    title: (data: any) =>
      `${data?.topics?.[0]?.name}, ${data?.topics?.[1]?.name} போன்ற அனைத்து பயண தலைப்புகளையும் ஆராயுங்கள்`,
    description: (data: any) =>
      `Exploreoffbeats இல் ${data?.topics?.[0]?.name} முதல் ${data?.topics?.[1]?.name} வரை பயண தலைப்புகள் பற்றிய ஒரு பரந்த வரம்பை ஆராயுங்கள். எங்கள் நிபுணர் கட்டுரைகளுடன் பயண குறிப்புகள் மற்றும் தகவலைப் பெறுங்கள்.`,
  },
  te: {
    title: (data: any) =>
      `${data?.topics?.[0]?.name}, ${data?.topics?.[1]?.name} వంటి అన్ని ప్రయాణ విషయాలను అన్వేషించండి`,
    description: (data: any) =>
      `Exploreoffbeats లో ${data?.topics?.[0]?.name} నుండి ${data?.topics?.[1]?.name} వరకు విస్తృత ప్రయాణ విషయాలను అన్వేషించండి. మా నిపుణుల వ్యాసాలతో ప్రయాణ చిట్కాలు మరియు సమాచారం పొందండి.`,
  },
  es: {
    title: (data: any) =>
      `Explora todos los temas como ${data?.topics?.[0]?.name}, ${data?.topics?.[1]?.name}`,
    description: (data: any) =>
      `Explora una amplia gama de temas de viaje desde ${data?.topics?.[0]?.name} hasta ${data?.topics?.[1]?.name} en Exploreoffbeats. Obtén información, consejos y más con nuestros artículos de viaje especializados.`,
  },
  fr: {
    title: (data: any) =>
      `Explorez tous les sujets comme ${data?.topics?.[0]?.name}, ${data?.topics?.[1]?.name}`,
    description: (data: any) =>
      `Explorez une large gamme de sujets de voyage allant de ${data?.topics?.[0]?.name} à ${data?.topics?.[1]?.name} sur Exploreoffbeats. Obtenez des informations, des conseils et plus encore avec nos articles de voyage d'experts.`,
  },
  ml: {
    title: (data: any) =>
      `${data?.topics?.[0]?.name}, ${data?.topics?.[1]?.name} പോലുള്ള എല്ലാ യാത്രാ വിഷയങ്ങളും പരിശോധിക്കുക`,
    description: (data: any) =>
      `Exploreoffbeats ലെ ${data?.topics?.[0]?.name} മുതൽ ${data?.topics?.[1]?.name} വരെ യാത്രാ വിഷയങ്ങളുടെ വിപുലമായ പരിധി അന്വേഷിക്കുക. ഞങ്ങളുടെ വിദഗ്ധ ലേഖനങ്ങളോടൊപ്പം യാത്രാ ടിപ്സുകളും അറിവുകളും നേടുക.`,
  },
  kn: {
    title: (data: any) =>
      `${data?.topics?.[0]?.name}, ${data?.topics?.[1]?.name} ಎಲ್ಲ ಪ್ರವಾಸ ವಿಷಯಗಳನ್ನು ಅನ್ವೇಷಿಸಿ`,
    description: (data: any) =>
      `Exploreoffbeats ನಲ್ಲಿ ${data?.topics?.[0]?.name} ಇಂದ ${data?.topics?.[1]?.name} ರವರೆಗೆ ಪ್ರವಾಸ ಸಂಬಂಧಿತ ವಿಷಯಗಳನ್ನು ಅನ್ವೇಷಿಸಿ. ನಮ್ಮ ತಜ್ಞರ ಲೇಖನಗಳೊಂದಿಗೆ ಪ್ರವಾಸ ಸಲಹೆಗಳು ಮತ್ತು ಮಾಹಿತಿಯನ್ನು ಪಡೆಯಿರಿ.`,
  },
};

export const NEXT_CACHE = 25 * 24 * 60 * 60;
export const CATEGORY_NEXT_CACHE = 2 * 60 * 60;
