// eslint-disable-next-line camelcase
import { Dancing_Script, Poppins, Space_Grotesk } from 'next/font/google';

export const spaceGrotesk = Space_Grotesk({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700'],
});
export const dancingScript = Dancing_Script({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700'],
});
export const poppins = Poppins({
  subsets: ['latin'],
  weight: ['400', '600', '700', '800'],
});
export const fontSize = 14;
export const typography = {
  fontSize,
  htmlFontSize: 16,
  fontFamily: poppins.style.fontFamily,
  body1: { fontSize },
  body2: { fontSize },
};
