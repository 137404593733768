import HomeLayout from 'components/layouts/home-layout';
import React from 'react';
import ThemeProvider from 'theme/theme-provider';

interface Subcategory {
  title: string;
  url: string;
}

interface Props {
  children: React.ReactNode;
  lang: string;
}

export default function LayoutProvider({ children, lang }: Props) {
  return (
    <ThemeProvider>
      <HomeLayout lang={lang}>{children}</HomeLayout>
    </ThemeProvider>
  );
}
