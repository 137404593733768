import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { H3, H6 } from 'components/typography';
import { footerTranslations } from 'helper/constant/static-content';
import Link from 'next/link';
import { createNavigation } from 'utils/create-navigation';
import { StyledFooterCard } from './style';

const Footer = ({ lang = 'en' }: { lang?: string }) => {
  const translation = footerTranslations[lang];

  // Translated links
  const translatedLinks = [
    {
      title: translation.quickLink,
      subcategories: [
        { title: translation.home, url: '/' },
        { title: translation.about, url: 'about-us' },
        { title: translation.privacyPolicy, url: 'privacy-policy' },
      ],
    },
    {
      title: translation.categories,
      subcategories: [
        { title: translation.maharashtra, url: `${translation.maharashtra}` },
      ],
    },
  ];
  return (
    <StyledFooterCard>
      <Grid container spacing={2} pt={0} mt={0}>
        {translatedLinks.map((link, index) => (
          <Grid item sm={3} md={4} spacing={2} key={index} pl={0} pt={0}>
            <H3>{link.title}</H3>
            {
              <Stack gap={0.5} pt={1}>
                {link.subcategories?.map((subCategory) => (
                  <Link
                    href={createNavigation(lang, subCategory.url)}
                    key={subCategory.title}
                  >
                    <H6
                      fontWeight={500}
                      key={subCategory.title}
                      sx={{
                        cursor: 'pointer',
                        '&:hover': { textDecoration: 'underline' },
                      }}
                    >
                      {subCategory.title}
                    </H6>
                  </Link>
                ))}
              </Stack>
            }
          </Grid>
        ))}
      </Grid>
    </StyledFooterCard>
  );
};

export default Footer;
